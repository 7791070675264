<template>
  <!-- Just contains the logo justified to the left. -->
  <v-container>
    <v-img
      :src="require('@/assets/banner.png')"
      class="mb-6"
    ></v-img>
    <div class="mb-4 pa-6" font-family="Terminus">
      <b>NightMargin (any)</b><br>
      Director, Lead Artist, Writer, Musician<br>
      <br>

      <b>GIRakaCHEEZER (he/him)</b><br>
      Lead Producer<br>
      <br>

      <b>Saniblues (he/him)</b><br>
      Programmer<br>
      <br>

      <b>Jeffrey Chiao (they/them)</b><br>
      Producer, Programmer<br>
      <br>

      <b>Izzy (they/them)</b><br>
      Programmer<br>
      <br>

      <b>Giftfox (she/they)</b><br>
      Programmer, Level Design<br>
      <br>

      <b>Near (they/them)</b><br>
      Environmental Artist<br>
      <br>

      <b>Nellie Melton (she/her)</b><br>
      Sprite Artist + General Art Assistance<br>
      <br>

      <b>Aaron Cherof (he/him)</b><br>
      Musician<br>
      <br>

      <b>Carlos Schvepper (he/him)</b><br>
      Trailer Animator<br>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'StaffPage',
  components: {
  }
}
</script>

<style scoped>
</style>
