<template>
  <v-app>
    <v-main>
      <!-- Header Bar -->
      <HeaderBar class="mt-4 ml-10" v-if="argPath == ''"/>

      <!-- Router Page Contents -->
      <RouterView transition="fade" />
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import HeaderBar from '@/components/HeaderBar.vue'

export default {
  name: 'App',

  components: {
    HeaderBar
  },

  data: () => ({
    galleryImages: [
      {
        "id" : 0,
        "src" : require("@/assets/1 bedroom.png")
      },
      {
        "id" : 1,
        "src" : require("@/assets/2 tableroom.png")
      },
      {
        "id" : 2,
        "src" : require("@/assets/3 plants.png")
      },
      {
        "id" : 3,
        "src" : require("@/assets/4 aqueduct.png")
      },
      {
        "id" : 4,
        "src" : require("@/assets/5 hesper.png")
      },
      {
        "id" : 5,
        "src" : require("@/assets/6 crafting.png")
      },
      {
        "id" : 6,
        "src" : require("@/assets/7 rooftop.png")
      },
      {
        "id" : 7,
        "src" : require("@/assets/8 friendship.png")
      }
    ]
  }),

  computed: {
    ...mapState(['activePage']),
    ...mapState(['argPath'])
  },
  methods: {
    ...mapActions(['setARGPath']),
    changeARGPath(path) {
      this.setARGPath(path);
    }
  }
};
</script>

<style>
@font-face {
  font-family: "Terminus";
  src: url("@/assets/fonts/TerminusTTF.ttf");
}

div {
  font-family: "Terminus";
  font-size: x-large;
}

.video-wrap {
  max-width: 800px;
  margin: 0 auto;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>