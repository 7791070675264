<template>
  <v-container>
    <v-row class="mt-3 mb-3 d-flex justify-center">
      <v-col cols="12" class="d-flex justify-center social-bar" >
          <v-btn
              v-for="link in linkValues"
              :key="link.id"
              icon
              compact
              class="mx-2 social-btn"
              :href="link.path"
              target="_blank"
          >
              <v-icon>
                  <v-img
                      :src="link.icon"
                      :alt="link.name"
                      max-width="24"
                      max-height="24"
                  />
              </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SocialBar",
  data () {
    return {
        linkValues: [
            {
                id: 0,
                name: "Kickstarter",
                icon: require("../assets/icons/kickstarter.svg"), // update to kickstarter
                path: "https://www.kickstarter.com/projects/futurecatgames/margin-of-the-strange",
            },
            {
                id: 1,
                name: "Steam",
                icon: require("../assets/icons/steam.svg"),
                path: "https://store.steampowered.com/app/2949750/Margin_of_the_Strange/",
            },
            {
                id: 2,
                name: "Discord",
                icon: require("../assets/icons/discord.svg"),
                path: "https://discord.gg/n2ZMD4zSJw",
            },
            {
                id: 3,
                name: "Twitter/X",
                icon: require("../assets/icons/twitter.svg"),
                path: "https://twitter.com/MarginStrange",
            },
        ],
    }
  }
};
</script>

<style scoped>
.social-bar {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}

.social-btn {
    transition: transform 0.2s ease-in-out;
}

.social-btn:hover {
    transform: scale(1.2);
}
</style>