<template>
  <v-img
    :src="src"
    :style="{ filter: isHovered ? 'brightness(80%)' : 'none' }"
    class="on-hover"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
    @onclick="console.log(src)"
  ></v-img>
</template>

<script>

  export default {
    name: 'GalleryImage',

    components: {
    },

    data: () => ({
      isHovered: false,
      placeholder: require('@/assets/placeholder_galleryphoto.png')
    }),

    props: {
      src: String
    }
  }
</script>

<style scoped>
.on-hover {
  transition: filter 0.3s ease;
}
</style>
