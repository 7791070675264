<template>
  <v-container>
    <center>
      
    </center>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'FooterBar',

  computed: {
    ...mapState(['activePage'])
  },

  methods: {
    ...mapActions(['setActivePage']),
    changePage(page) {
      this.setActivePage(page);
    }
  }
}
</script>

<style scoped>
</style>
