// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

const marginTheme = {
  dark: false,
  colors: {
    background: '#0D0B18',
    primary: '#0D0B18'
  }
}

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  {
    theme: {
      defaultTheme: 'marginTheme',
      themes: {
        marginTheme
      }
    }
  }
)
