<template>
    <div class="ml-15 mr-15">
        <!-- Featured Image -->
        <v-img
            :src="require('@/assets/banner.png')"
            class="mb-5"
        >
        </v-img>
        <div class="ml-5 mr-5">
            <h2>PRESS RELEASE 5/21/24 - MARGIN OF THE STRANGE</h2>
            <p class="mb-2">
                Today, on May 21th, 2024 @ 3 PM PT/6 PM ET, Future Cat Games launched the crowdfunding campaign for their next game, 
                <a href="https://www.kickstarter.com/projects/futurecatgames/margin-of-the-strange" target="_blank">Margin of the Strange.</a>
                It is seeking a goal of $62,812 USD and is running from May 21st, 2024->June 20th, 2024.
                The Kickstarter's story page contains a deeper dive into the gameplay, world, characters, and the team working on the project.
                It also features various rewards, including an early bird discount for the game, physical merch, beta access, and an opportunity to add a NPC of your own.
            </p>
            <p class="mb-2">
                In addition, a full trailer featuring animation (done by the mysterious Carlos Schvepper), and more gameplay premiered at the same time on Nightmargin's YouTube channel.
                <a href="https://www.youtube.com/watch?v=Hst9j_-kkLs" target="_blank">The full trailer</a>
                showcases the animated sequences we plan on adding to the game, as well as a sampling of the game's many locations and vibes.
                The trailer's score was composed by Aaron Cherof, composer of Cobalt Core and the recent soundtracks for Minecraft!
                Aaron will be joining us for more additional tracks in the final game too.
            </p>
            <p class="mb-6">
                Oh, and we now have
                <a href="https://store.steampowered.com/app/2949750/Margin_of_the_Strange/" target="_blank">a Steam page up for Margin of the Strange</a>
                as well, for the wishlisting needs of the many.
            </p>
            <h2>ABOUT MARGIN OF THE STRANGE</h2>
            <p class="mb-2">
                Margin of the Strange is a gardening-themed mystery adventure game about the world of human dreams. You play as Ling, a young man experiencing the phenomenon of "dreaming" for seemingly the first time. He discovers that the dream world is actually a type of ecosystem - a sprawling, crystalline, alien dimension dictated by Strange physical laws. Over the course of 6 weeks, Ling seeks to find out more about this world in order to unravel the mysteries surrounding his own life... and his own death.
            </p>
            <p class="mb-6">
                Margin of the Strange is the next project by Future Cat Games, and is the next project from NightMargin, the lead artist, writer and musician of indie darling OneShot.
            </p>
            <h2>ABOUT FUTURE CAT GAMES</h2>
            <p>
                Future Cat Games makes adventure games. You may have played our previous title, OneShot! Margin of the Strange is the second game by Future Cat.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PressKitPage'
}
</script>

<style scoped>
h2 {
    font-weight: bold;
    font-style: italic;
}
</style>