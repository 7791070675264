import { createStore } from 'vuex';

export default createStore({
    state: {
        activePage: 'Main',
        argPath: ''
    },
    mutations: {
        setActivePage(state, page) {
            state.activePage = page;
        },
        setARGPath(state, path) {
            state.argPath = path
        }
    },
    actions: {
        setActivePage({ commit }, page) {
            commit('setActivePage', page);
        },
        setARGPath({ commit }, path) {
            commit('setARGPath', path);
        }
    },
    getters: {
        activePage: state => state.activePage,
        argPath: state => state.argPath
    }
});