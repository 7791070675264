<template>
  <!-- Just contains the logo justified to the left. -->
  <v-container>
    <v-row
      class="pa-10"
      cols="12"
    >
      <v-col :cols="smallBlockSize()">
        <h2>FACT SHEET</h2>
        <p><b>Developer:</b> Future Cat Games</p>
        <p><b>Release Date:</b> TBD</p>
        <p><b>Platforms:</b> PC (via Steam)</p>
        <p><b>Website:</b> marginofthestrange.com</p>
        <p><b>Price:</b> TBD</p>
        <p><b>Engine:</b> GameMaker</p>
      </v-col>
      <v-col :cols="mediumBlockSize()">
        <h2>CONTACT</h2>
        <p><b>Business Inquiries/Press Requests:</b>
          <a href="mailto:futurecat@marginofthestrange.com">
            futurecat@marginofthestrange.com
          </a>
        </p>
        <p><b>X (formerly Twitter):</b>
          <a href="https://twitter.com/MarginStrange">
            @MarginStrange
          </a>
        </p>
        <p><b>Kickstarter:</b>
          <a target="_blank" href="https://www.kickstarter.com/projects/futurecatgames/margin-of-the-strange">
            https://www.kickstarter.com/projects/futurecatgames/margin-of-the-strange
          </a>
        </p>
        <p><b>Discord:</b>
          <a target="_blank" href="https://discord.gg/n2ZMD4zSJw">
            https://discord.gg/n2ZMD4zSJw
          </a>
        </p>
      </v-col>
      <v-col :cols="smallBlockSize()">
        <h2>ASSETS</h2>
        <p>
          <a target="_blank" href="https://drive.google.com/drive/folders/1opASt3z-9kN7NlhTZ550assmuVLP9jp1?usp=sharing">
            Full Asset Folder
          </a>
        </p>
        <p>
          <a target="_blank" href="https://drive.google.com/drive/folders/1Ai6w17CyHDVdSFa4UyMYP_iMOZssQqPz?usp=drive_link">
            Download Logo and Icon Assets
          </a>
        </p>
        <p>
          <a target="_blank" href="https://drive.google.com/drive/folders/1lS_193IwetlsK4EDDmJhGEwo4iTmXHgC?usp=drive_link">
            Download Video Assets
          </a>
        </p>
        <p>
          <a target="_blank" href="https://drive.google.com/drive/folders/1bMW4EPZo2xfTkET8hw1nWl2z3qwn0EZG?usp=sharing">
            Download Image Assets
          </a>
        </p>
      </v-col>
      <v-col :cols="mediumBlockSize()">
        <h2>DESCRIPTION</h2>
        <p class="mb-4">Margin of the Strange is a gardening-themed mystery adventure game about the world of human dreams. You play as Ling, a young man experiencing the phenomenon of "dreaming" for seemingly the first time. He discovers that the dream world is actually a type of ecosystem - a sprawling, crystalline, alien dimension dictated by Strange physical laws. Over the course of 6 weeks, Ling seeks to find out more about this world in order to unravel the mysteries surrounding his own life... and his own death.</p>
        <p class="mb-4">Margin of the Strange is the next project by Future Cat Games, and is the next project from NightMargin, the lead artist, writer and musician of indie darling OneShot. It will receive a crowdfunding campaign running from May 21st, 2024 to June 20th, 2024 on Kickstarter, seeking a goal of $62,812. The pre-launch page is live for those to follow. A Full Trailer containing more gameplay and animated sequences will also go live on May 21st, 2024. A Steam page will be available to wishlist in the near future.</p>
        <v-btn v-if="!isMobile()" class="mt-2" to="/news">Check out our latest press release here!</v-btn>
        <v-btn v-else class="mt-2" to="/news">Our latest press release!</v-btn>
      </v-col>
      <v-col :cols="smallBlockSize()">
        <h2>FEATURES</h2>
        <li class="ml-6">Grow plants to reverse local entropy</li>
        <li class="ml-6">Make friends, they will have information</li>
        <li class="ml-6">Stare at lamps and drink sugar water (moth things)</li>
        <li class="ml-6">Make friends with moths, they will help you with the 3 previous things</li>
        <li class="ml-6">The next project from the developers of acclaimed indie game "OneShot"</li>
      </v-col>
      <v-col class="mb-8" :cols="mediumBlockSize()">
        <h2>HISTORY</h2>
        <p class="mb-4">Margin of the Strange originated as a personal project by NightMargin which was conceived of over a decade ago, and is now being developed as a fully-fledged narrative adventure game! The current version of the project began early production in late 2021, with a newly established team assisting with the project, and has been slowly in-development since.</p>
        <p>With the formal announcement of the game and the Kickstarter, Margin of the Strange will be entering a new phase in the project - the success of the campaign would allow the project to enter full-time development!</p>
      </v-col>
      <v-col :cols="halfBlockSize()">
        <h2>VIDEOS</h2>
        <a target="_blank" href="https://drive.google.com/drive/folders/1lS_193IwetlsK4EDDmJhGEwo4iTmXHgC?usp=drive_link">
          <p class="mb-4">DOWNLOAD OUR TRAILERS IN FULL RESOLUTION HERE</p>
        </a>

        <div class="video-container mb-4">
          <iframe
            class="mb-4"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/J98fBInCuaU?si=ByMuwJqCNd3xxN9K"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          >
          </iframe>
        </div>
        <p>(FULL TRAILER - COMING MAY 21st)</p>
      </v-col>
      <v-col :cols="halfBlockSize()">
        <h2>KEY ART</h2>
        <a target="_blank" href="https://drive.google.com/drive/folders/1bMW4EPZo2xfTkET8hw1nWl2z3qwn0EZG?usp=sharing">
          <p class="mb-4">DOWNLOAD OUR KEY ART + SCREENSHOTS IN FULL RESOLUTION HERE</p>
        </a>
        <h2>LOGO</h2>
        <a target="_blank" href="https://drive.google.com/drive/folders/1Ai6w17CyHDVdSFa4UyMYP_iMOZssQqPz?usp=drive_link">
          <p class="mb-4">DOWNLOAD OUR LOGO ASSETS IN FULL RESOLUTION HERE</p>
        </a>
        <h2>ABOUT FUTURE CAT GAMES</h2>
        <p>Future Cat Games makes adventure games. You may have played our previous title, OneShot! Margin of the Strange is the second game by Future Cat.</p>
      </v-col>
      <v-col :cols="fullBlockSize()">
        <h2>CREDITS</h2>
        <div class="mb-4" font-family="Terminus">
          <b>NightMargin (any)</b><br>
          Director, Lead Artist, Writer, Musician<br>
          <br>

          <b>GIRakacheezer (he/him)</b><br>
          Lead Producer<br>
          <br>

          <b>Saniblues (he/him)</b><br>
          Programmer<br>
          <br>

          <b>Jeffrey Chiao (they/them)</b><br>
          Producer, Programmer<br>
          <br>

          <b>Zaz (they/them)</b><br>
          Programmer<br>
          <br>

          <b>Giftfox (she/they)</b><br>
          Programmer, Level Design<br>
          <br>

          <b>Nellie Melton (she/her)</b><br>
          Sprite Artist + General Art Assistance<br>
          <br>

          <b>Aaron Cherof (he/him)</b><br>
          Musician<br>
          <br>

          <b>Carlos Schvepper (he/him)</b><br>
          Trailer Animator<br>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'PressKitPage',
  components: {
  },

  computed: {
    ...mapState(['activePage'])
  },

  methods: {
    ...mapActions(['setActivePage']),
    changePage(page) {
      this.setActivePage(page);
    },

    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },

    smallBlockSize () {
      return this.isMobile() ? "12" : "4"
    },

    mediumBlockSize () {
      return this.isMobile() ? "12" : "8"
    },

    halfBlockSize () {
      return this.isMobile() ? "12" : "6"
    },

    fullBlockSize () {
      // Redundant but in place just in case we need it.
      return this.isMobile() ? "12" : "12"
    },

    navigateToPressRelease () {
      // HACKY WAY of doing this but we're BOOKING it.
      this.changePage("PressRelease")
    }
  }
}
</script>

<style scoped>
p {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
