import { createRouter, createWebHistory } from 'vue-router'
// import App from '@/App.vue'

// Page Imports - ARG Pages are loaded elsewhere
import HomePage from '@/components/Pages/HomePage.vue'
import PressKitPage from '@/components/Pages/PressKitPage.vue'
import PressReleasePage from '@/components/Pages/PressReleasePage.vue'
import StaffPage from '@/components/Pages/StaffPage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/press',
    name: 'Press Kit',
    component: PressKitPage
  },
  {
    path: '/news',
    name: 'News',
    component: PressReleasePage
  },
  {
    path: '/staff',
    name: 'Staff',
    component: StaffPage
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path == '/press-kit') {
    // console.log("Oh we're going!")
    // store.commit('appStore/setActivePage', 'PressRelease')
  }
  next()
})

export default router
