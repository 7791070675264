<template>
  <!-- Just contains the logo justified to the left. -->
  <v-row :class="getRowClass()">
    <!-- Logo Goes Here -->
    <v-img
      :src="require('@/assets/logo.png')" contains
      class="ml-4"
      max-width="192px"
    ></v-img>
    <v-separator />
    <v-btn
      class="mx-4 mt-auto mb-auto"
      to="/"
      variant="text"
    >
      Home
    </v-btn>
    <v-btn
      class="mx-4 mt-auto mb-auto"
      to="/staff"
      variant="text"
    >
      Staff
    </v-btn>
    <v-btn
      class="mx-4 mt-auto mb-auto justify-right"
      to="/press"
      variant="text"
    >
      Press Kit
    </v-btn>
    <v-btn
      class="mx-4 mt-auto mb-auto"
      variant="text"
      target="_blank"
      href="https://www.kickstarter.com/projects/futurecatgames/margin-of-the-strange"
      v-if="!isMobile()"
    >
      Kickstarter
    </v-btn>
    <v-btn
      class="mx-4 mt-auto mb-auto"
      variant="text"
      target="_blank"
      href="https://store.steampowered.com/app/2949750/Margin_of_the_Strange/"
      v-if="!isMobile()"
    >
      Steam
    </v-btn>
    <v-btn
      class="mx-4 mt-auto mb-auto"
      variant="text"
      target="_blank"
      href="https://discord.gg/n2ZMD4zSJw"
      v-if="!isMobile()"
    >
      Discord
    </v-btn>
    <v-btn
      class="mx-4 mt-auto mb-auto"
      variant="text"
      target="_blank"
      href="https://twitter.com/MarginStrange"
      v-if="!isMobile()"
    >
      Twitter
    </v-btn>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'HeaderBar',
  computed: {
    ...mapState(['activePage']),
    ...mapState(['argPath'])
  },

  methods: {
    ...mapActions(['setActivePage']),
    changePage(page) {
      this.setActivePage(page);
    },
    ...mapActions(['setARGPath']),
    changeARGPath(path) {
      this.setARGPath(path);
    },
    
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },

    getRowClass() {
      return this.isMobile() ? "ml-auto mr-auto" : "ml-20"
    }
  }
}
</script>

<style scoped>
</style>
