<template>
  <v-container>
    <v-row>
      <v-col
        v-for="img in images"
        :key="img.id"
        @click="lightbox(img.src)"
        cols="12"
        md="6"
      >
        <GalleryImage
          :src="img.src"
        />
      </v-col>
    </v-row>
    <v-dialog v-model="lightboxActive" max-width="80%" @click="lightboxActive = false">
        <v-card>
          <v-img :src="selectedImage" @click="lightboxActive = false" contain></v-img>
        </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import GalleryImage from '../components/GalleryImage.vue'

  export default {
    name: 'PhotoGallery',

    components: {
      GalleryImage
    },

    data: () => ({
      selectedImage: null,
      lightboxActive: false
    }),

    props: {
      images: Array
    },

    methods: {
      lightbox(_img) {
        this.selectedImage = _img
        this.lightboxActive = true
      }
    }
  }
</script>
